<template>
    <div>
        <a-spin size="large" class="loading" :spinning="loading" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: true,
            delay: Math.random() * 600 + 200,
        };
    },
    created() {
        if (this.$route.query.force == '1') {
            setTimeout(this.checkCode, this.delay);
        } else {
            setTimeout(this.checkToken, this.delay);
        }
    },
    methods: {
        checkToken() {
            if (this.$store.state.token && this.$store.state.token.length == 24) {
                this.$api('checkLogin').then((res) => {
                    if (res) {
                        this.finishCheck();
                    } else {
                        this.checkCode();
                    }
                });
            } else {
                this.checkCode();
            }
        },
        checkCode() {
            if (this.$route.name === undefined) {
                setTimeout(this.checkCode, 50);
                return;
            }
            if (this.$route.query.code && this.$route.query.code.length == 43) {
                this.getToken();
            } else {
                this.getCode();
            }
        },
        getCode() {
            this.$api('getLoginConfig').then((res) => {
                let corp_id = res.corp_id;
                let agent_id = res.agent_id;
                let redirect_url = encodeURIComponent(window.location.href);
                if (window.navigator.userAgent.toLowerCase().indexOf('wxwork') == -1) {
                    // 是，直接跳转至微信域内进行自动授权认证
                    let url = `https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=${corp_id}&agentid=${agent_id}&redirect_uri=${redirect_url}&state=STATE`;
                    window.self.location = url;
                } else {
                    // 否，跳转至微信扫码界面进行等待用户扫码进行授权认证
                    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corp_id}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_base#wechat_redirect`;
                    window.self.location = url;
                }
            });
        },
        getToken() {
            this.$api('login', { code: this.$route.query.code }).then((res) => {
                if (res.result == '登录成功') {
                    this.$store.commit('login', { token: res.token });
                    this.finishCheck();
                } else {
                    this.$message.error('参数无效，请重试');
                    setTimeout(this.$router.replace, 300, '/index');
                }
            });
        },
        async finishCheck() {
            this.$message.success('登录成功');
            let user_info = await this.$api('getUserInfo');
            this.$store.commit('user_info', user_info);
            let permission = await this.$api('getPermission');
            this.$store.commit('permission', permission);
            setTimeout(() => {
                this.loading = false;
                this.$router.push('/todo');
            }, this.delay);
        },
    },
};
</script>

<style scoped>
.loading {
    zoom: 4;
    height: 25vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
